import { useCallback, useEffect, useState } from 'react'

import { Metadata, retrieveMetadata } from '../utils/http'
import { getUser } from '../reducers/UserReducer'
import { useSelector } from 'react-redux'
import { debounce, isArray } from 'lodash'

const DEFAULT_DATA = { activeKey: 1, payload: {} }
const DEFAULT_PAGE_SIZE = 20

/**
 * Hook that handles api calls with pagination and caching.
 * @param {object} params 
 * @param {(user:object, parameters:object) => any} params.request
 * @param {object} [params.defaultParameters]
 * @param {Metadata} [params.defaultMetadata]
 */
const useQuery = ({ request, defaultParameters = {}, defaultMetadata = new Metadata() }) => {
  const user = useSelector(state => getUser(state.getUser))

  const [data, setData] = useState(DEFAULT_DATA)
  const [metadata, setMetadata] = useState(defaultMetadata)
  const [parameters, setParameters] = useState(defaultParameters)
  const [loading, setLoading] = useState(false)

  const wrappedRequest = useCallback(() => {
    const pagination = metadata.toQueryParameters()

    if (!isArray(data.payload[pagination.page])) {
      setLoading(true)
      const promise = request(user, { ...parameters, ...pagination })

      if (promise instanceof Promise) {
        promise.then(json => {
          if (json?.data) {
            setData({
              ...data,
              payload: { ...data.payload, [pagination.page]: json.data },
              activeKey: pagination.page
            })
          }

          if (json?.meta) {
            setMetadata(retrieveMetadata(json.meta))
          }

          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    }
  }, [request, parameters, data, metadata.page, metadata.pageSize, user.id, setMetadata, setData, setLoading])

  const refetch = useCallback(() => {
    setMetadata(new Metadata({ ...metadata }))
  }, [metadata])

  const next = useCallback(() => {
    if(metadata.next < metadata.last) {
      setMetadata(new Metadata({ 
        ...metadata, 
        page: metadata.next, 
        prev: metadata.page,
        next: metadata.next + 1
      }))
    }
  }, [metadata])

  const previous = useCallback(() => {
    if(metadata.prev >= metadata.first) {
      setMetadata(new Metadata({ 
        ...metadata, 
        page: metadata.prev, 
        prev: metadata.prev - 1,
        next: metadata.page
      }))
    }
  }, [metadata])

  const first = useCallback(() => {
    setMetadata(new Metadata({ ...metadata, prev: metadata.first, next: metadata.first + 1, page: metadata.first }))
  }, [metadata])

  const last = useCallback(() => {
    setMetadata(new Metadata({ ...metadata, prev: metadata.last - 1, next: metadata.last, page: metadata.last }))
  }, [metadata])

  useEffect(() => {
    const fetch = debounce(wrappedRequest, 300)

    fetch()

    return () => fetch.cancel()
  }, [wrappedRequest])

  const mutate = useCallback(mutator => {
    const newData = { ...data }

    newData.payload[newData.activeKey] = mutator(newData.payload[newData.activeKey])

    setData(newData)
  }, [data, setData])

  const mutateParameters = useCallback(newParameters => {
    setParameters({ ...parameters, ...newParameters })
    setMetadata(new Metadata({ 
      ...metadata, 
      page: metadata.first, 
      pageSize: newParameters?.pageSize ?? DEFAULT_PAGE_SIZE 
    }))

    setData({ ...DEFAULT_DATA })
    setLoading(true)
  }, [setParameters, setMetadata, setData, setLoading])

  return {
    all: data.payload,
    data: (data.payload[metadata.page] ?? []),
    parameters,
    metadata,
    loading,
    setMetadata,
    setParameters: mutateParameters,
    refetch,
    setData,
    next,
    previous,
    first,
    last,
    mutate
  }
}

export default useQuery
